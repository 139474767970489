import React, { useState, useEffect, useRef } from 'react';
import _ from 'underscore';
import { Engine, Scene, Vector3, Color3, Mesh, AssetsManager, StandardMaterial, Texture, Layer,
    Animation, ArcRotateCamera, HighlightLayer, HemisphericLight, Color4 } from '@babylonjs/core';
import { AdvancedDynamicTexture, Rectangle } from '@babylonjs/gui';
import '@babylonjs/loaders';
import { OBJFileLoader } from '@babylonjs/loaders';
import { Ships, GOLD_PER_CP } from '../definitions/Ships';
import { balanceToDisplay } from '../definitions/OmegaDefaults';
import { DefaultModule, DefaultEffect, AllEffects,
    Effects, EffectNamesLookup } from '../definitions/Modules';
import { OmegaLoadingScreen } from '../common/OmegaLoadingScreen';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import './Combat.css';

const LASER_LENGTH_MS = 333;
const ROUND_LENGTH_MS = 500;
const MOVE_FRAMERATE = 40;
const HEALTH_BAR_WIDTH = 0.75;
const MOVE_SPEED = 10;
const LHS_COLOR = Color3.Yellow();
const RHS_COLOR = Color3.Green();
const MAX_BACKGROUND_VARIANTS = 3;


// props: result, payout
export const Combat = (props) => {
    const [ round, setRound ] = useState(0);
    const [ showingResult, setShowingResult ] = useState(false);
    const [ combatLog, setCombatLog ] = useState('');
    const [ resourcesLoaded, setResourcesLoaded ] = useState(false);
    const [ interrupt ] = useState({});
    const reactCanvas = useRef(null);
    let shipMeshesLhs = [ [], [], [], [] ];
    let shipMeshesRhs = [ [], [], [], [] ];
    let healthPlanesLhs = [ [], [], [], [] ];
    let healthPlanesRhs = [ [], [], [], [] ];

    const addHealthBarToMesh = (clonedMesh, currentShip, i, isLhs) => {
        const healthPlaneWidth = 1;
        const healthPlane = Mesh.CreatePlane(`health-plane-${currentShip}-${i}`, healthPlaneWidth);
        healthPlane.billboardMode = Mesh.BILLBOARDMODE_ALL;
        healthPlane.position = new Vector3(clonedMesh.position.x, 1, clonedMesh.position.z);

        const healthBarWidth = HEALTH_BAR_WIDTH;
        const healthBarHeight = 0.15;
        const healthTexture = AdvancedDynamicTexture.CreateForMesh(healthPlane);
        const healthOuterBar = new Rectangle();
        healthOuterBar.width = healthBarWidth;
        healthOuterBar.height = healthBarHeight;
        healthOuterBar.background = 'black';
        healthOuterBar.horizontalAlignment = 'left';
        const healthInnerBar = new Rectangle();
        healthInnerBar.width = healthBarWidth;
        healthInnerBar.height = healthBarHeight;
        healthInnerBar.background = isLhs ? '#FDD835' : '#66BB6A';
        healthInnerBar.horizontalAlignment = 'left';

        healthTexture.addControl(healthOuterBar);
        healthTexture.addControl(healthInnerBar);

        return {
            plane: healthPlane,
            innerBar: healthInnerBar,
        };
    };

    const afterImportMeshes = (scene, rootMesh, currentShip,
        basePosition, count, direction, isLhs) => {

        const highlightLayer = scene.getHighlightLayerByName('hl1');

        rootMesh.position = new Vector3(basePosition + currentShip * direction, 0, 0);
        rootMesh.rotation = isLhs
            ? Ships[currentShip].visuals.fightRotationLhs.clone()
            : Ships[currentShip].visuals.fightRotationRhs.clone();

        rootMesh.scalingDeterminant = 0.00017 * Ships[currentShip].scale;
        rootMesh.material = new StandardMaterial(`texture{currentShip}`, scene);
        rootMesh.material.diffuseTexture = new Texture(Ships[currentShip].asset + Ships[currentShip].textures.diffuse, scene);
        rootMesh.shipIndex = 0;

        if (isLhs) {
            shipMeshesLhs[currentShip] = [ rootMesh ];
            healthPlanesLhs[currentShip] = [ addHealthBarToMesh(rootMesh, currentShip, 0, isLhs) ];
            // highlightLayer.addMesh(rootMesh, LHS_COLOR);
            // _.each(rootMesh.getChildMeshes(), (mesh) => {
            //     highlightLayer.addMesh(mesh, LHS_COLOR);
            // });
        } else {
            shipMeshesRhs[currentShip] = [ rootMesh ];
            healthPlanesRhs[currentShip] = [ addHealthBarToMesh(rootMesh, currentShip, 0, isLhs) ];
            // highlightLayer.addMesh(rootMesh, RHS_COLOR);
            // _.each(rootMesh.getChildMeshes(), (mesh) => {
            //     highlightLayer.addMesh(mesh, RHS_COLOR);
            // });
        }

        for (let i = 0; i < count - 1; i++) {
            const clonedMesh = rootMesh.clone();
            clonedMesh.shipIndex = i + 1;

            // highlightLayer.addMesh(clonedMesh, isLhs ? LHS_COLOR : RHS_COLOR);
            // _.each(clonedMesh.getChildMeshes(), (childMesh) => {
            //     highlightLayer.addMesh(childMesh, isLhs ? LHS_COLOR : RHS_COLOR);
            // });

            if (i % 2 === 0) {
                clonedMesh.position.z -= (Math.floor(i / 2) + 1) * Ships[currentShip].combatScale;
            } else {
                clonedMesh.position.z += (Math.floor(i / 2) + 1) * Ships[currentShip].combatScale;
            }

            const planeDef = addHealthBarToMesh(clonedMesh, currentShip, i + 1, isLhs);

            if (isLhs) {
                shipMeshesLhs[currentShip].push(clonedMesh);
                healthPlanesLhs[currentShip].push(planeDef);
            } else {
                shipMeshesRhs[currentShip].push(clonedMesh);
                healthPlanesRhs[currentShip].push(planeDef);
            }
        }
    };


    const loadResources = (scene) => {
        return new Promise((resolve, reject) => {
            const assetsManager = new AssetsManager(scene);

            for (let index = 0; index < Ships.length; index++) {
                shipMeshesLhs[index] = [];
                shipMeshesRhs[index] = [];

                const countLhs = props.result.selectionLhs[index];
                const countRhs = props.result.selectionRhs[index];
                if (countLhs > 0 || countRhs > 0) {
                    const task = assetsManager.addMeshTask(index, '',
                        Ships[index].asset,
                        Ships[index].fileName);
                    task.onSuccess = (task) => {
                        let mesh = task.loadedMeshes[0];
                        if (countLhs > 0) {
                            afterImportMeshes(scene, mesh,
                                index, 10, countLhs, 1, true);
                            mesh = mesh.clone();
                        }
                        if (countRhs > 0) {
                            afterImportMeshes(scene, mesh,
                                index, -10, countRhs, -1, false);
                        }
                        task.loadedMeshes = null;
                        task.reset();
                    };
                }
            }

            assetsManager.onFinish = () => {
                resolve();
            };

            scene._assetsManager = assetsManager;

            assetsManager.load();
        });
    };

    const moveShips = (scene, move, isLhs, shipHpsLhs, shipHpsRhs) => {
        const meshes = isLhs ? shipMeshesLhs[move.sourceType] : shipMeshesRhs[move.sourceType];
        const mesh = meshes[move.source];
        const healthPlanes = isLhs ? healthPlanesLhs[move.sourceType] : healthPlanesRhs[move.sourceType];
        const healthPlane = healthPlanes[move.source];

        const alreadyThereLhs = _.compact(_.map(shipMeshesLhs, (meshes, shipType) => {
            return _.find(meshes, (mesh) => {
                return mesh.position.x === move.targetPosition &&
                    mesh.shipIndex === move.source &&
                    shipHpsLhs[shipType][mesh.shipIndex] > 0;
            });
        }));
        const alreadyThereRhs = _.compact(_.map(shipMeshesRhs, (meshes, shipType) => {
            return _.find(meshes, (mesh) => {
                return mesh.position.x === move.targetPosition &&
                    mesh.shipIndex === move.source &&
                    shipHpsRhs[shipType][mesh.shipIndex] > 0;
            });
        }));
        const alreadyThere = alreadyThereLhs.length + alreadyThereRhs.length;

        const posYLhs = _.map(alreadyThereLhs, (mesh) => {
            return mesh.position.y;
        });
        const posYRhs = _.map(alreadyThereRhs, (mesh) => {
            return mesh.position.y;
        });

        let targetY = alreadyThere;
        for (let proposalY = 0; proposalY < alreadyThere; proposalY++) {
            if (!_.contains(posYLhs, proposalY) &&
                !_.contains(posYRhs, proposalY)) {
                targetY = proposalY;
            }
        }

        return new Promise((resolve/*, reject*/) => {
            if (mesh.position.x === move.targetPosition) {
                return resolve();
            }

            const framerate = MOVE_FRAMERATE;
            const slide = new Animation(_.uniqueId(), 'position.x', framerate,
                Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
            const direction = isLhs ? -1 : 1;
            const keyFrames = [
                {
                    frame: 0,
                    value: mesh.position.x,
                },
                {
                    frame: framerate,
                    value: mesh.position.x + Math.abs(move.targetPosition - mesh.position.x) * direction,
                },
                {
                    frame: 2*framerate,
                    value: move.targetPosition,
                }
            ];
            slide.setKeys(keyFrames);

            const adjustY = new Animation(_.uniqueId(), 'position.y', framerate,
                Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
            const keyFramesAdjustY = [
                {
                    frame: 0,
                    value: mesh.position.y,
                },
                {
                    frame: framerate,
                    value: mesh.position.y + ((targetY - mesh.position.y) / 2),
                },
                {
                    frame: 2*framerate,
                    value: targetY,
                }
            ];
            adjustY.setKeys(keyFramesAdjustY);

            const healthBarAdjustX = new Animation(_.uniqueId(), 'position.x', framerate,
                Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
            const keyFramesHealthBarAdjustX = [
                {
                    frame: 0,
                    value: mesh.position.x,
                },
                {
                    frame: framerate,
                    value: mesh.position.x + Math.abs(move.targetPosition - mesh.position.x) * direction,
                },
                {
                    frame: 2*framerate,
                    value: move.targetPosition,
                }
            ];
            healthBarAdjustX.setKeys(keyFramesHealthBarAdjustX);

            const healthBarAdjustY = new Animation(_.uniqueId(), 'position.y', framerate,
                Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
            const keyFramesHealthBarAdjustY = [
                {
                    frame: 0,
                    value: mesh.position.y + 1,
                },
                {
                    frame: framerate,
                    value: mesh.position.y + 1 + ((targetY - mesh.position.y) / 2),
                },
                {
                    frame: 2*framerate,
                    value: targetY + 1,
                }
            ];
            healthBarAdjustY.setKeys(keyFramesHealthBarAdjustY);

            mesh.animations = [ slide, adjustY ];
            scene.beginAnimation(mesh, 0, 2 * framerate, false, MOVE_SPEED, resolve);

            healthPlane.plane.animations = [ healthBarAdjustX, healthBarAdjustY ];
            scene.beginAnimation(healthPlane.plane, 0, 2 * framerate, false, MOVE_SPEED);
        });
    };

    const applyHpsToVisuals = (scene, targetType, target, isLhs, shipHpsLhs, shipHpsRhs) => {
        const hpPerShip = Ships[targetType].stats.hp;
        const hpsLeft = isLhs ? shipHpsRhs[targetType][target] : shipHpsLhs[targetType][target];
        const mesh = isLhs ? shipMeshesRhs[targetType][target] : shipMeshesLhs[targetType][target];
        const healthPlane = isLhs ? healthPlanesRhs[targetType][target] : healthPlanesLhs[targetType][target];

        if (hpsLeft > 0) {
            const leftPercentage = hpsLeft / hpPerShip;
            healthPlane.innerBar.width = leftPercentage * HEALTH_BAR_WIDTH;
        }

        if (hpsLeft <= 0) {
            mesh.dispose();
            healthPlane.plane.dispose();
        }
    };

    let localLog = '';

    const logAttack = (move, isLhs) => {
        const prefix = isLhs ? '[Attacker]' : '[Defender]';
        const newEntry = `${prefix} ${Ships[move.sourceType].name} hits ${Ships[move.targetType].name} for ${move.damage} damage.`;
        localLog = newEntry + '\n' + localLog;
        setCombatLog(localLog);
    };

    const logRoundStart = (round) => {
        const newEntry = `Round ${round + 1} begins.\n\n`;
        localLog = newEntry + localLog;
        setCombatLog(localLog);
    };

    const showLaser = (scene, sourceType, sourceMesh, targetMesh, isLhs) => {
        const mat = new StandardMaterial('laserMat', scene);
        mat.alpha = 0.6;
        mat.diffuseColor = isLhs ? LHS_COLOR : RHS_COLOR;
        mat.emissiveColor = new Color4(1, 1, 1, 0.2);
        mat.backFaceCulling = false;

        const lines = Mesh.CreateTube('laser', [
            sourceMesh.position,
            targetMesh.position
        ], Ships[sourceType].visuals.beamWidth, 64, null, 0, scene, false, Mesh.FRONTSIDE);
        lines.material = mat;
        lines.convertToFlatShadedMesh();

        setTimeout(() => {
            lines.dispose();
        }, LASER_LENGTH_MS);
    };

    const showAttacks = (scene, move, isLhs) => {
        const sourceMeshes = isLhs ? shipMeshesLhs : shipMeshesRhs;
        const targetMeshes = isLhs ? shipMeshesRhs : shipMeshesLhs;
        const sourceMesh = sourceMeshes[move.sourceType][move.source];
        const targetMesh = targetMeshes[move.targetType][move.target];

        showLaser(scene, move.sourceType, sourceMesh, targetMesh, isLhs);
    };

    const playMove = (scene, move, isLhs, shipHpsLhs, shipHpsRhs) => {
        let movePromise;

        if (!move) {
            return new Promise((resolve) => {
                resolve();
            });
        }

        if (move.moveType === 1) {
            movePromise = new Promise((resolve, reject) => {
                showAttacks(scene, move, isLhs);
                const shipHps = isLhs ? shipHpsRhs : shipHpsLhs;
                shipHps[move.targetType][move.target] -= move.damage;
                applyHpsToVisuals(scene, move.targetType, move.target, isLhs, shipHpsLhs,
                    shipHpsRhs);
                logAttack(move, isLhs);

                setTimeout(resolve, ROUND_LENGTH_MS);
            });
        } else {
            movePromise = moveShips(scene, move, isLhs, shipHpsLhs, shipHpsRhs);
        }

        // if (move.effectsRhs && move.effectsRhs.rangeDebuff > 0) {
        //     console.log(move.effectsRhs);
        // }

        // const newEffectsLhs = _.map(move.effects_lhs, (effect, index) => {
        //     return _.clone(shipHpsLhs[index] > 0
        //         ? effect
        //         : DefaultEffect);
        // });

        // const newEffectsRhs = _.map(move.effects_rhs, (effect, index) => {
        //     return _.clone(shipHpsRhs[index] > 0
        //         ? effect
        //         : DefaultEffect);
        // });

        // setRunningEffectsLhs(newEffectsLhs);
        // setRunningEffectsRhs(newEffectsRhs);

        return movePromise;
    };

    const playMoves = (scene, lhsMoves, rhsMoves, shipHpsLhs, shipHpsRhs) => {
        const _recursiveMover = async (ind, mainResolver) => {
            const lhsMovesPerShip = lhsMoves[ind];
            const rhsMovesPerShip = rhsMoves[ind];

            if (interrupt.interrupted) {
                return mainResolver();
            }

            const maxMove = Math.max(lhsMovesPerShip.length, rhsMovesPerShip.length);

            const moveShip = async (i) => {
                const moveLhs = i < lhsMovesPerShip.length && lhsMovesPerShip[i];
                const moveRhs = i < rhsMovesPerShip.length && rhsMovesPerShip[i];
                const movePromiseLhs = moveLhs
                    ? playMove(scene, moveLhs, true, shipHpsLhs, shipHpsRhs)
                    : Promise.resolve;
                const movePromiseRhs = moveRhs
                    ? playMove(scene, moveRhs, false, shipHpsLhs, shipHpsRhs)
                    : Promise.resolve;
                return Promise.all([movePromiseLhs, movePromiseRhs]);
            };

            for (let i = 0; i < maxMove; i++) {
                await moveShip(i);

                if (interrupt.interrupted) {
                    return mainResolver();
                }
            }

            if (ind + 1 < Math.max(lhsMoves.length, rhsMoves.length)) {
                _recursiveMover(ind + 1, mainResolver);
            } else {
                mainResolver();
            }
        }

        return new Promise((resolve, reject) => {
            _recursiveMover(0, resolve);
        });
    };

    const playRound = (scene, round, shipHpsLhs, shipHpsRhs) => { // recursive
        if (round >= props.result.rounds) {
            setShowingResult(true);
            return;
        }

        if (interrupt.interrupted) {
            return;
        }

        setRound(round);
        logRoundStart(round);

        const lhsMoves = _.filter(props.result.lhsMoves, (move) => {
            return move.round === round && move.moveType !== 0;
        });
        const rhsMoves = _.filter(props.result.rhsMoves, (move) => {
            return move.round === round && move.moveType !== 0;
        });

        const lhsMovesPadded = _.map(_.range(Ships.length), (shipIndex) => {
            return _.where(lhsMoves, {
                sourceType: shipIndex,
            });
        });

        const rhsMovesPadded = _.map(_.range(Ships.length), (shipIndex) => {
            return _.where(rhsMoves, {
                sourceType: shipIndex,
            });
        });

        playMoves(scene, lhsMovesPadded, rhsMovesPadded, shipHpsLhs, shipHpsRhs).then(() => {
            playRound(scene, round + 1, shipHpsLhs, shipHpsRhs);
        });
    };

    const playCombat = (scene) => {
        const shipHpsLhs = _.map(props.result.selectionLhs, (count, index) => {
            return Array.apply(null, {length: count}).map(() => Ships[index].stats.hp);
        });
        const shipHpsRhs = _.map(props.result.selectionRhs, (count, index) => {
            return Array.apply(null, {length: count}).map(() => Ships[index].stats.hp);
        });

        playRound(scene, 0, shipHpsLhs, shipHpsRhs);
    };

    const onSceneMount = (canvas, scene) => {
        scene.getEngine().loadingScreen = new OmegaLoadingScreen();

        const camera = new ArcRotateCamera('camera1',
            Math.PI / 2, Math.PI / 6, 24, Vector3.Zero(), scene);
        camera.minZ = 0.001;
        camera.lowerRadiusLimit = 8;
        camera.upperRadiusLimit = 36;
        scene.activeCameras.push(camera);
        camera.attachControl(canvas, true);

        const light = new HemisphericLight('light1', new Vector3(0, 0, 1), scene);
        light.intensity = 0.8;

        scene.clearColor = new Color4(0, 0, 0, 0);

        // const background = new Layer('background',
        //     '/assets/images/sector.jpg', scene);
        // background.isBackground = true;
        // background.texture.level = 0;
        // background.texture.wAng = 0;

        scene.getEngine().runRenderLoop(() => {
            scene.render();
        })

        const emptyFight = _.isEmpty(_.compact(props.result.selectionLhs)) &&
            _.isEmpty(_.compact(props.result.selectionRhs));

        const playerFn = () => {
            setResourcesLoaded(true);
            playCombat(scene);
        };

        if (!emptyFight) {
            loadResources(scene).then(playerFn);
        } else {
            playerFn();
        }
    };

    const getWinnerString = () => {
        if (props.result.lhsDead) {
            return 'Defender Wins';
        } else if (props.result.rhsDead) {
            return 'Attacker Wins';
        } else {
            return 'Draw';
        }
    }

    useEffect(() => {
        if (reactCanvas.current) {
            const canvas = document.createElement('canvas');
            reactCanvas.current.appendChild(canvas);

            const engine = new Engine(canvas, true, { stencil: true }, true);
            engine.getCaps().parallelShaderCompile = false;
            // engine.disableTextureBindingOptimization = true;
            const scene = new Scene(engine);
            const highlightLayer = new HighlightLayer('hl1', scene);
            highlightLayer.blurHorizontalSize = 0.2;
            highlightLayer.blurVerticalSize = 0.2;

            OBJFileLoader.SKIP_MATERIALS = true;

            if (scene.isReady()) {
                onSceneMount(canvas, scene);
            } else {
                scene.onReadyObservable.addOnce(scene => onSceneMount(canvas, scene));
            }

            const resize = () => {
                scene.getEngine().resize();
            }

            if (window) {
                window.addEventListener('resize', resize);
            }

            return () => {
                scene._assetsManager && scene._assetsManager.reset();
                highlightLayer.removeAllMeshes();
                highlightLayer.dispose();
                _.each(scene.meshes, (mesh) => {
                    if (mesh.material) {
                        mesh.material.diffuseTexture && mesh.material.diffuseTexture.dispose();
                        mesh.material.dispose();
                    }
                });
                _.invoke(scene.meshes, 'dispose');
                scene.dispose();
                engine.dispose();
                shipMeshesRhs = null;
                shipMeshesLhs = null;
                canvas.parentElement.removeChild(canvas);
                OBJFileLoader.SKIP_MATERIALS = false;
                if (window) {
                    window.removeEventListener('resize', resize);
                }
            }
        }
    }, [reactCanvas]);

    const getShipsLost = (isLhs, elClassName) => {
        const shipsLost = isLhs
            ? props.result.shipsLostLhs
            : props.result.shipsLostRhs;

        return (
            <div className={elClassName}>
                <div className="shipsLostTitle">
                    {isLhs? 'Attacker' : 'Defender'} Ships Lost:
                </div>
                <div className="shipsLostInner">
                    {_.map(Ships, (ship, index) => {
                        return (
                            <div className="shipLostInfo" key={index}>
                                {ship.name}: {shipsLost[index]}
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    }

    const getPayoutInfo = () => {
        return props.payout
            ? (
                <div className="payoutInfo">
                    Payout: {balanceToDisplay(props.payout)}
                    <OfflineBoltIcon fontSize="small"/>
                </div>
            )
            : <div/>;
    }

    const resultDialogClassName = `resultDialog ${props.result.rhsDead ? 'victory' : ''}`;
    const backgroundVariant = props.result.seed % MAX_BACKGROUND_VARIANTS;
    const mainClassName = 'Combat' + (resourcesLoaded ? ` loaded variant-${backgroundVariant}` : '');

    return (
        <div className={mainClassName}>
            <div ref={reactCanvas}>
            </div>
            {resourcesLoaded &&
                <div className="ui">
                    <div className="uiElement combatLog">
                        <pre>{combatLog}</pre>
                    </div>
                    <div className="uiElement doneBox bottomBox" onClick={() => {
                        setShowingResult(true);
                        interrupt.interrupted = true;
                    }}>
                        FINISH
                    </div>
                    <div className="uiElement currentRound">
                        Round {round+1}
                    </div>
                    <div className="miniLogoBox"></div>
                    {showingResult &&
                        <div className="result">
                            <div className={resultDialogClassName}>
                                <div className="winner">
                                    {getWinnerString()}
                                </div>
                                <div className="shipsLost">
                                    {getShipsLost(true, 'shipsLostLhs')}
                                    {getShipsLost(false, 'shipsLostRhs')}
                                </div>
                                <div className="payout">
                                    {getPayoutInfo()}
                                </div>
                                <div className="exitButton" onClick={props.onCancel}>
                                    EXIT
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    );
}
