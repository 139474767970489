import React, { useEffect, useState, useRef } from 'react';
import _, { sortBy } from 'underscore';
import './AttackFleet.css';
import { GiSubmarineMissile } from 'react-icons/gi';
import { balanceToDisplay } from '../../definitions/OmegaDefaults';
import LinearProgress from '@material-ui/core/LinearProgress';
import SwiperCore, { Navigation, Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/swiper-react.cjs';
import 'swiper/swiper-bundle.min.css';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';

SwiperCore.use([Navigation, Pagination, A11y]);

// props: facade, onAttackFleet, disabled, loading, setLoading
export const AttackFleet = (props) => {
    const [defenders, setDefenders] = useState(null);
    const [sortByScore, setSortByScore] = useState(true);

    const applySort = (_defenders, _sortByScore) => {
        const sorter = _sortByScore
            ? (lhs, rhs) => rhs.wins - lhs.wins
            : (lhs, rhs) => rhs.value - lhs.value;
        const defendersSorted = _defenders.sort(sorter);
        setDefenders(defendersSorted);
    };

    useEffect(() => {
        async function fetchData() {
            try {
                const defenders = await props.facade.getAllDefenders();
                applySort(defenders, sortByScore);
            } catch (error) {
            }

            props.setLoading(false);
        }

        fetchData();
    }, []);

    const attackFleet = (address, selection, variants, balance) => {
        !props.disabled && props.onAttackFleet(address, selection, variants, balance);
    };

    const sliderSettings = {
        slidesPerView: 3,
        spaceBetween: 10,
        navigation: true,
        pagination: {
            type: 'progressbar',
        },
    };

    const onSortByScore = () => {
        setSortByScore(true);
        applySort(defenders, true);
    };

    const onSortByTokens = () => {
        setSortByScore(false);
        applySort(defenders, false);
    };

    const readyClass = props.disabled ? 'defenderInnerReady' : '';

    return (
        <div className="AttackFleet">
            {props.loading && 
                <div className="progress">
                    <LinearProgress/>
                </div>
            }
            {!props.loading && defenders &&
                <>
                    <Swiper className="defenders" {...sliderSettings}>
                        {_.map(defenders, (defender) =>
                            <SwiperSlide key={defender.address}>
                                <div 
                                    className="defender" 
                                    onClick={() => attackFleet(defender.address, defender.selection,
                                        defender.variants, defender.value)
                                    }
                                >
                                    <div className="defenderInner">
                                        {props.disabled &&
                                            <div className="registerFirst">
                                                Register First
                                            </div>
                                        }
                                        <div className={readyClass}>
                                            <div className="defenderName">
                                                {defender.name}
                                            </div>
                                            <div className="defenderValue">
                                                <OfflineBoltIcon/>
                                                {balanceToDisplay(defender.value)}
                                            </div>
                                            <div className="wins">
                                                {defender.wins} Wins {defender.losses} Losses
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )}
                    </Swiper>
                    <div className="sortWrapper">
                        <div className="sort sortScore" onClick={onSortByScore}>Sort by Score</div>
                        <div className="sort sortTokens" onClick={onSortByTokens}>Sort by Tokens</div>
                    </div>
                </>
            }
            {!props.loading && !defenders &&
                <div>Error</div>
            }
        </div>
    );
};
