import React, { useEffect, useState, useRef } from 'react';
import { proofToClassName } from '../../definitions/Planets';
import './ModuleInfo.css';
import { EffectNamesLookup, moduleQualityToClassName, moduleToDescription, moduleToType, tokenIdToName } from '../../definitions/Modules';
import _ from 'underscore';


// props: module, tokenId
export const ModuleInfo = React.memo((props) => {
    const allValues = _.values(props.module.moduleStats);
    const sumAllValues = allValues.reduce((a, b) => a + b, 0);
    const innerClassName = moduleQualityToClassName(sumAllValues);
    const rarityClassName = `rarity gradeBackground ${innerClassName}`;
    const nameClassName = `name grade ${innerClassName}`;
    const statsClassName = 'stats ' + innerClassName;

    return (
        <div className="ModuleInfo">
            <div className={rarityClassName}>
                <span>{innerClassName.toUpperCase()}</span>
            </div>
            <div className={nameClassName}>
                <span>{tokenIdToName(props.tokenId)}</span>
                <span className="nftId">
                    NFT ID: {props.tokenId}
                </span>
            </div>
            <div className={statsClassName}>
                <div className="type">
                    {moduleToType(props.module.moduleStats)}
                </div>
                <div className="value">
                    {sumAllValues}%
                </div>
                <div className="description">
                    <div>
                        {moduleToDescription(props.module.moduleStats)}
                    </div>
                    <div className="quality">
                        Quality: {moduleQualityToClassName(sumAllValues)}
                    </div>
                </div>
            </div>
        </div>
    );
});
