import { GiBarefoot } from 'react-icons/gi';
import { GiCloverSpiked } from 'react-icons/gi';
import { GiBlindfold } from 'react-icons/gi';
import { GiBrokenShield } from 'react-icons/gi';
import { GiBrokenSkull } from 'react-icons/gi';
import { GiBrokenArrow } from 'react-icons/gi';
import _ from 'underscore';

const moduleNamesPrefixesString = 'Robust Gaping Large Dazzling Delicate Assorted Detailed Harmonious Miniature Closed Small Internal Broad Combative Tested Swift Distinct Strong Decorous Perpetual Futuristic Automatic Boundless Substantial Valuable Self-Sufficient Rational Programmed Bionic Extreme Essential Dynamic ';
const moduleNamesPrefixes = moduleNamesPrefixesString.split(' ');

const moduleNamesMiddleString = 'Adamantite Atium Bavarium Cavorite Chelonium Chronoton Corrodium Durium Harbenite Inerton Lerasium Nanite Octiron Polydenum Radianite Regalite Runite Scrith Septium Taydenite Timonium Uridium Xirdalium Xithricite';
const moduleNamesMiddle = moduleNamesMiddleString.split(' ');

const moduleNamesSuffixesString = 'Orchestrator Expeditor Engager Integrator Enhancer Converger Cultivator Deployer Evolver Generator Incubator Optimizer Reactor Emulator Eradicator Exterminator Protector';
const moduleNamesSuffixes = moduleNamesSuffixesString.split(' ');

export const tokenIdToName = (tokenId) => {
    return moduleNamesPrefixes[tokenId % moduleNamesPrefixes.length] + ' ' +
        moduleNamesMiddle[(tokenId * 5) % moduleNamesMiddle.length] + ' ' +
        moduleNamesSuffixes[(tokenId * 7) % moduleNamesSuffixes.length];
};

export const AllEffects = [ 'snare', 'root', 'blind', 'attack_debuff',
    'defence_debuff', 'range_debuff'];
export const EffectNamesLookup = [ 'Snare', 'Root', 'Blind', '-Atk', '-Def', '-Range' ];

export const DefaultModule = {
    snare: 0,
    root: 0,
    blind: 0,
    attack_debuff: 0,
    defence_debuff: 0,
    range_debuff: 0,
};

export const DefaultEffect = {
    snare: 0,
    root: 0,
    blind: 0,
    attack_debuff: 0,
    defence_debuff: 0,
    range_debuff: 0,
};

export const moduleToType = (mod) => {
    if (mod.snare > 0) { return 'Snare'; }
    if (mod.root > 0) { return 'Root'; }
    if (mod.blind > 0) { return 'Blind'; }
    if (mod.attackDebuff > 0) { return 'Curse'; }
    if (mod.defenceDebuff > 0) { return 'Disable'; }
    if (mod.rangeDebuff > 0) { return 'Hack'; }
};

export const moduleToDescription = (mod) => {
    if (mod.snare > 0) { return 'Reduces speed of enemy ships'; }
    if (mod.root > 0) { return 'Freezes enemy ships in place'; }
    if (mod.blind > 0) { return 'Renders enemy ships unable to act'; }
    if (mod.attackDebuff > 0) { return 'Causes enemy ships to deal less damage'; }
    if (mod.defenceDebuff > 0) { return 'Increase damage taken of enemy ships'; }
    if (mod.rangeDebuff > 0) { return 'Reduces range of enemy ships'; }
};

export const moduleQualityToClassName = (proof, max) => {
    max = max || 100;

    const lookup = {
        [Math.floor(100 * max / 100)]: 'primal',
        [Math.floor(99 * max / 100)]: 'mythical',
        [Math.floor(89 * max / 100)]: 'legendary',
        [Math.floor(79 * max / 100)]: 'epic',
        [Math.floor(69 * max / 100)]: 'rare',
        [Math.floor(49 * max / 100)]: 'uncommon',
        [Math.floor(29 * max / 100)]: 'common',
    };

    let clName = '';
    _.each(_.keys(lookup).reverse(), (lookupKey) => {
        if (proof <= lookupKey) {
            clName = lookup[lookupKey];
        }
    });

    return clName;
};
