import { GiPathDistance, GiHealthIncrease, GiHealthDecrease, GiSpeedometer,
    GiBowArrow, GiBrokenArrow } from 'react-icons/gi';
import { RiPinDistanceFill } from 'react-icons/ri';
import { IoMdSpeedometer } from 'react-icons/io';
import { FiShield, FiShieldOff } from 'react-icons/fi';

export const Targeting = {
    Furthest: 'Furthest',
    Closest: 'Closest',
    HighestHp: 'HighestHp',
    LowestHp: 'LowestHp',
    HighestSpeed: 'HighestSpeed',
    LowestSpeed: 'LowestSpeed',
    HighestDefence: 'HighestDefence',
    LowestDefence: 'LowestDefence',
    HighestAttack: 'HighestAttack',
    LowestAttack: 'LowestAttack',
};

export const TargetingToIcon = {
    [Targeting.Furthest]: GiPathDistance,
    [Targeting.Closest]: RiPinDistanceFill,
    [Targeting.HighestHp]: GiHealthIncrease,
    [Targeting.LowestHp]: GiHealthDecrease,
    [Targeting.HighestSpeed]: GiSpeedometer,
    [Targeting.LowestSpeed]: IoMdSpeedometer,
    [Targeting.HighestDefence]: FiShield,
    [Targeting.LowestDefence]: FiShieldOff,
    [Targeting.HighestAttack]: GiBowArrow,
    [Targeting.LowestAttack]: GiBrokenArrow,
};

export const TargetingToTitle = {
    [Targeting.Furthest]: 'Furthest',
    [Targeting.Closest]: 'Closest',
    [Targeting.HighestHp]: 'Highest HP',
    [Targeting.LowestHp]: 'Lowest HP',
    [Targeting.HighestSpeed]: 'Highest Speed',
    [Targeting.LowestSpeed]: 'Lowest Speed',
    [Targeting.HighestDefence]: 'Highest Defence',
    [Targeting.LowestDefence]: 'Lowest Defence',
    [Targeting.HighestAttack]: 'Highest Attack',
    [Targeting.LowestAttack]: 'Lowest Attack',
};

export const TargetingToDescription = {
    [Targeting.Furthest]: 'Ships will try to target ships furthest from them, within their extended range',
    [Targeting.Closest]: 'Ships will try to target ships closest to them, within their extended range',
    [Targeting.HighestHp]: 'Ships will try to target ships with highest nominal Hit Points, within their extended range',
    [Targeting.LowestHp]: 'Ships will try to target ships with lowest nominal Hit Points, within their extended range',
    [Targeting.HighestSpeed]: 'Ships will try to target ships with highest current Speed, within their extended range',
    [Targeting.LowestSpeed]: 'Ships will try to target ships with lowest current Speed, within their extended range',
    [Targeting.HighestDefence]: 'Ships will try to target ships with highest current Defence, within their extended range',
    [Targeting.LowestDefence]: 'Ships will try to target ships with lowest current Defence, within their extended range',
    [Targeting.HighestAttack]: 'Ships will try to target ships with highest current Attack, within their extended range',
    [Targeting.LowestAttack]: 'Ships will try to target ships with lowest current Attack, within their extended range',
};
